import { Link } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { boldedWords } from "../../../helpers"
import { SearchSectionProps, Item } from "./SearchSection.d"
import { chunkArray } from "../../../helpers"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import Icon from "../../atoms/Icon"

/**
 *
 * @author Stu Finn
 * @summary - Display individual section for Search Results
 * @param {itemArray[]} seriesaccessories - Array of individual results with title and slug
 * @param {string[]} boldWords - Array of strings that should be bolded in the UI. These correspond to the search query
 * @param {string} sectionTitle - Title of the results section (e.g. Vehicles)
 *
 * @returns <SearchSection />
 *
 * @todo - Accept icon for section
 * @todo - Unit tests
 */

const SearchSection: React.FC<SearchSectionProps> = ({
  itemArray,
  boldWords,
  sectionTitle,
  category,
  searchQueryCount,
  searchDataEntry,
}) => {
  const { language, _ } = useContext(LanguageContext)
  // If itemArray is undefined, return null
  if (itemArray.length < 1) return null

  // State to toggle section open or closed
  const [isClosed, setClosed] = useState<boolean>(false)

  // State to control "view more" pagination button
  const [state, setState] = useState<number>(0)

  // State to track how many results are hidden in pagination (displayed next to button)
  const [resultCount, setResultCount] = useState<number | null>(null)

  // Determines how long an array is when it is long
  const long: number = 5

  // Boolean to determine if there are 5 or more search results to list in this section
  const isLong: boolean = itemArray?.length > long

  // If isLong is true, break results into sections of 5, otherwise store all results
  const formattedResults: Item[] | [] = isLong
    ? chunkArray(itemArray, long)
    : itemArray

  // If isLong is true, store total number of chunks
  const totalChunks: number | null = isLong ? formattedResults?.length : null

  // When state changes, update resultCount
  useEffect(() => {
    const total = itemArray?.length - long
    const newTotal = total - state * long
    setResultCount(newTotal)
  }, [state])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const languagePrefix = language === "en" ? "" : `/${language}`

  return (
    <div css={[tw`grid gap-y-3 w-full max-w-5xl`]}>
      {category && (
        <div css={[tw`flex items-center border-b border-gray-300 py-2`]}>
          {/* Render icon based on sectionTitle */}
          <button
            onClick={() => (isClosed ? setClosed(false) : setClosed(true))}
          >
            {category === "vehicles" && (
              <Icon.Tire
                color="gray-600"
                css={[tw`h-5 pr-3`, tw`md:(pr-5 h-6)`]}
              />
            )}
          </button>
          {category === "accessories" && (
            <Icon.Checkmark
              color="gray-600"
              css={[tw`h-4 pr-3`, tw`md:(pr-5 h-6)`]}
            />
          )}
          {category === "parts" && (
            <Icon.Globe
              color="gray-600"
              css={[tw`h-5 pr-3`, tw`md:(pr-5 h-6)`]}
            />
          )}
          {category === "offers" && (
            <Icon.SaveSearch
              color="gray-600"
              css={[tw`h-5 pr-3`, tw`md:(pr-5 h-6)`]}
            />
          )}
          {category === "more" && (
            <Icon.Location
              color="gray-600"
              css={[tw`h-5 pr-3`, tw`md:(pr-5 h-6)`]}
            />
          )}
          <button
            onClick={() => (isClosed ? setClosed(false) : setClosed(true))}
            css={[tw`flex justify-center items-center w-full`]}
            id={sectionTitle}
          >
            <h2
              css={[
                tw`font-sans font-book text-gray-600 text-lg pb-1 flex-grow text-left capitalize`,
                tw`md:(text-xl pb-2)`,
              ]}
            >
              {sectionTitle} ({itemArray?.length})
            </h2>
            <Icon.Chevron
              color="red-400"
              css={[
                tw`h-4 transition-all duration-300`,
                isClosed && tw`transform -rotate-180`,
              ]}
              direction="down"
            />
          </button>
        </div>
      )}
      {/* Search-links container */}

      <div
        css={[
          tw`grid ml-6 transition-all ease-out duration-500 max-h-full h-full overflow-hidden gap-y-2 pb-6`,
          tw`md:ml-12`,
          isClosed && tw`max-h-0 h-0 pb-0 transform duration-300 ease-in`,
        ]}
      >
        {formattedResults?.map((item: Item | any, index: number) => {
          // If isLong is flagged, render chunked results
          if (isLong) {
            return (
              state >= index && (
                <div css={[tw`flex flex-col gap-5`]}>
                  {[item]?.map((node: Item[]) =>
                    node?.map((item: Item, index: number) => {
                      // Run title through parser/formatter function to determine which words to italicize
                      const bolded = boldedWords(item?.title, boldWords)

                      return (
                        <Link
                          to={`${languagePrefix}/${item?.slug}`}
                          css={[tw`text-base font-semibold`, tw`md:(text-2xl)`]}
                          key={`${item?.slug}-${index}`}
                          onClick={() => {
                            trackTealEvent({
                              tealium_event: "cta_click",
                              page_type: "search",
                              link_href: `/${item?.slug}`,
                              coupon_module_text: item?.title,
                              search_query: searchDataEntry,
                              search_returns: searchQueryCount,
                            })
                          }}
                          analytics-id={`search results:results:${index + 1}`}
                        >
                          <span
                            dangerouslySetInnerHTML={{ __html: bolded }}
                          ></span>
                        </Link>
                      )
                    })
                  )}
                </div>
              )
            )
          }

          // Run title through parser/formatter function to determine which words to italicize
          const bolded = boldedWords(item?.title, boldWords)
          // If isLong is NOT flagged, render the items as normal
          return (
            <Link
              to={`${languagePrefix}/${item?.slug}`}
              css={[tw`text-base font-semibold`, tw`md:(text-2xl)`]}
              key={`${item?.slug}-${index}`}
            >
              <span dangerouslySetInnerHTML={{ __html: bolded }}></span>
            </Link>
          )
        })}

        {/* If isLong is flagged, render "view more" button and resultCount */}
        {isLong && state !== totalChunks - 1 && (
          <section css={[tw`flex items-center gap-4 mt-3`]}>
            <button
              onClick={() => {
                setState(prev => (prev >= totalChunks ? 0 : prev + 1))
                trackTealEvent({
                  tealium_event: "cta_click",
                  page_type: "search",
                  link_href: `Load More:${sectionTitle}`,
                  coupon_module_text: "Load More",
                  search_query: searchDataEntry,
                  search_returns: searchQueryCount,
                  page_name: "search results",
                })
              }}
              analytics-id={`load more:results:${sectionTitle}`}
              css={[
                tw`text-xs text-red-400 font-semibold uppercase`,
                tw`hover:(text-black)`,
              ]}
            >
              {_("LOAD MORE")}
            </button>
            <div css={[tw`text-xs`]}>
              {resultCount} {_("more results")}
            </div>
          </section>
        )}
      </div>
    </div>
  )
}

export default SearchSection
