import React, { useContext, useEffect, useState } from "react"
import { graphql, Link, PageProps } from "gatsby"
import tw from "twin.macro"
import { Layout } from "../components/atoms/Layout"
import { SearchContext } from "../contexts/Search"
import { search } from "../clients/Search"
import { SearchResults } from "../components/molecules/SearchResults"
import { SearchBar } from "../components/molecules/SearchBar"
import { DynamicSeriesCta } from "../components/molecules/DynamicSeriesCta"
import { ButtonLink } from "../components/atoms/Button"
import { SearchQuery } from "../components/atoms/SearchQuery"
import { ImageBlocks } from "../components/organisms/Series"
import { LanguageContext } from "../contexts/Language"
import { getCTALinkValue } from "../helpers"
import Icon from "../components/atoms/Icon"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import { SEO } from "../components/atoms/SEO"

/**
 *
 * @author Stu
 * @summary - Search page, to display search results. Currenly uses context for the search query, but component-level state may be adequate in the future.
 * @param {any} data - GraphQL data
 *
 * @returns <SearchPage />
 *
 * @todo - Persist search query in search bar (both search bar in header and embedded search bar on /search page)
 * @todo - Finalize i18n for the Search page.  It's almost there, but I (Stu) am not sure how to make sure it's setting i18n_refs. This is likely more of a CMS thing. Will use language context.
 * @todo - Provide correct links for ImageBlocks
 * @todo - Create a better initial view for search page, before a query is performed. There are no comps for this currently.
 * @todo - Consider moving "Popular Search" user data from "Search Settings" in CMS to the Search Page interface in the CMS.
 * @todo - Unit tests
 */

const Search: React.FC<PageProps> = ({ data, location }: any) => {
  const { page } = data
  const { sidebarSections } = page

  const { _ } = useContext(LanguageContext)
  const [searchData] = useContext(SearchContext)
  const [searchResults, setSearchResults] = useState(null)
  const [seriesState, setSeriesState] = useState<string>("default")

  const [vehiclesCount, setVehiclesCount] = useState<number>()
  const [accessoriesCount, setAccessoriesCount] = useState<number>()
  const [partsCount, setPartsCount] = useState<number>()
  const [offersCount, setOffersCount] = useState<number>()
  const [otherCount, setOtherCount] = useState<number>()

  const { handleTealView } = useTealiumView()

  // Find index of imageCta component
  const imageCtaIndex = sidebarSections.findIndex(
    (node: any) => node._type === "imageCta"
  )

  // If imageCtaIndex is returned as -1 by findIndex, it doesn't exist (wasn't added as a sidebar section in Sanity)
  const imageCtaExists = imageCtaIndex !== -1

  // If imageCta exists, assign it to this variable
  const imageCta = imageCtaExists ? sidebarSections[imageCtaIndex] : null

  // If imageCta exists, assign the series variants' series names to this variable
  const activeVariants = imageCta
    ? imageCta.seriesVariants?.map((node: any) => node?.seriesRef?.name)
    : null

  // Runs when search query changes
  const fetchSearchResults = async () => {
    if (searchData?.query !== "") {
      const searchResponse = await search({ query: searchData?.query })

      setSearchResults(searchResponse)
    } else {
      setSearchResults(null)
    }
  }

  // replace hybrid urls with series url + "?hybrid=true"
  const [filteredSearch, setFilteredSearch] = useState()
  useEffect(() => {
    if (!searchResults) return
    const results = searchResults.records?.series.map((result: any) => {
      const isHybrid =
        result.category === "Hybrids" ||
        (result.series && result.slug?.includes("hybrid"))
      if (!result.slug.includes("hybrid=true") && isHybrid) {
        if (result.slug?.charAt(result.slug?.length - 1) !== "/") {
          result.slug += "/"
        }
        result.slug = result.slug?.includes("?")
          ? `${result.slug}&hybrid=true`
          : `${result.slug}?hybrid=true`
      }
      return result
    })
    setFilteredSearch(results)
    const {
      series,
      accessories,
      featuredaccessory,
      parts,
      partsdetail,
      seriesaccessories,
      xseriesmodel,
      toyoguard,
      toyotacare,
      xseries,
      offers,
      offer,
    } = searchResults?.records
    setAccessoriesCount(
      accessories?.concat(seriesaccessories, featuredaccessory).length
    )
    setPartsCount(parts?.concat(partsdetail).length)
    setVehiclesCount(series?.concat(xseries, xseriesmodel).length)
    setOffersCount(offers?.concat(offer).length)
    setOtherCount([].concat(toyoguard, toyotacare).length)
  }, [searchResults])
  // Runs when search query changes
  const detectSeriesInSearchQuery = () => {
    if (!activeVariants) return

    setSeriesState("default")
    activeVariants?.map((node: any) => {
      if (searchData?.query?.toLowerCase().includes(node.toLowerCase()))
        setSeriesState(node)
    })
  }

  const [tealiumDataLoaded, setTealiumDataLoaded] = useState(false)

  // Run on initial search and when search query changes
  useEffect(() => {
    fetchSearchResults().then(() => {
      detectSeriesInSearchQuery()
      setTealiumDataLoaded(true)
    })
  }, [searchData?.query])

  function scrollToSection(targetId: string) {
    // Get the target element by ID
    const targetSection = document?.getElementById(targetId)

    // If the screen is on a mobile device and the section is found, scroll to it
    if (targetSection) {
      const headerHeight = 80 // Sticky header height
      const rect = targetSection.getBoundingClientRect()

      // Calculate the scroll position with the header height
      const scrollPosition = rect.top + window.scrollY - headerHeight

      // Scroll to the calculated position
      window.scrollTo({ top: scrollPosition, behavior: "smooth" })
    }
  }

  useEffect(() => {
    if (tealiumDataLoaded) {
      handleTealView({
        page_name: "search results",
        page_type: "search",
        tealium_event: "search_results",
        search_query: searchData?.query,
        search_returns: `${searchResults?.record_count},${vehiclesCount},${accessoriesCount},${partsCount},${offersCount},${otherCount}`,
      })
      setTealiumDataLoaded(false)
    }
  }, [tealiumDataLoaded])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      {/* Search bar */}
      <section
        aria-label="Search Bar Section"
        css={[tw`w-full bg-gray-50 shadow-3`]}
      >
        <div
          css={[
            tw`max-w-desktop py-10 mx-auto text-lg pb-14 px-6 sm:(px-8) lg:(px-10)`,
          ]}
        >
          <h1 css={[tw`text-5xl`]}>{_("Toyota Search")}</h1>
        </div>
        <div css={[tw`max-w-[66rem] w-full mx-auto`]}>
          <SearchBar mobileView={false} />
        </div>
      </section>

      {/* Styling SearchSideBar Nav Mobile */}
      {vehiclesCount > 0 ||
      accessoriesCount > 0 ||
      partsCount > 0 ||
      offersCount > 0 ||
      otherCount > 0 ? (
        <div css={[tw`bg-gray-100 p-6 rounded-lg m-8`, tw`lg:(hidden)`]}>
          {vehiclesCount < 1 ? null : (
            <button
              css={[tw`flex items-center py-2`]}
              onClick={() => {
                scrollToSection("Vehicles")
                trackTealEvent({
                  tealium_event: "cta_click",
                  page_type: "search",
                  link_href: "search",
                })
              }}
            >
              <Icon.Tire
                color="gray-600"
                css={[tw`h-5 pr-3`, tw`md:(pr-4 h-6)`]}
              />
              <h2
                css={[
                  tw`font-sans font-book text-gray-600 text-lg text-left capitalize`,
                  tw`md:(text-xl)`,
                ]}
              >
                {_("Vehicles")} ({vehiclesCount})
              </h2>
            </button>
          )}
          {accessoriesCount < 1 ? null : (
            <button
              css={[tw`flex items-center py-2`]}
              onClick={() => {
                scrollToSection("Accessories")
              }}
            >
              <Icon.Checkmark
                color="gray-600"
                css={[tw`h-4 pr-3`, tw`md:(pr-4 h-5)`]}
              />
              <h2
                css={[
                  tw`font-sans font-book text-gray-600 text-lg text-left capitalize`,
                  tw`md:(text-xl)`,
                ]}
              >
                {_("Accessories")} ({accessoriesCount})
              </h2>
            </button>
          )}
          {partsCount < 1 ? null : (
            <button
              css={[tw`flex items-center py-2`]}
              onClick={() => {
                scrollToSection("Parts")
              }}
            >
              <Icon.Globe
                color="gray-600"
                css={[tw`h-5 pr-3`, tw`md:(pr-4 h-6)`]}
              />
              <h2
                css={[
                  tw`font-sans font-book text-gray-600 text-lg text-left capitalize`,
                  tw`md:(text-xl)`,
                ]}
              >
                {_("Parts")} ({partsCount})
              </h2>
            </button>
          )}
          {offersCount < 1 ? null : (
            <button
              css={[tw`flex items-center py-2`]}
              onClick={() => {
                scrollToSection("Offers")
              }}
            >
              <Icon.Search
                color="gray-600"
                css={[tw`h-5 pr-3`, tw`md:(pr-4 h-6)`]}
              />
              <h2
                css={[
                  tw`font-sans font-book text-gray-600 text-lg text-left capitalize`,
                  tw`md:(text-xl)`,
                ]}
              >
                {_("Offers")} ({offersCount})
              </h2>
            </button>
          )}
          {otherCount < 1 ? null : (
            <button
              css={[tw`flex items-center py-2`]}
              onClick={() => {
                scrollToSection("More")
              }}
            >
              <Icon.Location
                color="gray-600"
                css={[tw`h-5 pr-3`, tw`md:(pr-4 h-6)`]}
              />
              <h2
                css={[
                  tw`font-sans font-book text-gray-600 text-lg text-left capitalize`,
                  tw`md:(text-xl)`,
                ]}
              >
                {_("More")} ({otherCount})
              </h2>
            </button>
          )}
        </div>
      ) : null}

      {/* End Styling SearchSideBar Nav */}

      {/* Content */}
      {searchResults && (
        <section
          css={[
            tw`flex flex-col max-w-[66rem] mx-auto py-11`,
            tw`lg:(flex-row)`,
          ]}
        >
          {/* Search results container */}
          <section
            aria-label="Search Results Section"
            css={[tw`flex flex-col px-8 w-full`]}
          >
            {/* Result count */}
            <h1 css={[tw`mb-16 text-2xl font-light`]}>
              1-{searchResults?.record_count} {_("of")}{" "}
              <span css={[tw`font-semibold`]}>
                {searchResults?.record_count}
              </span>{" "}
              {_("Results")}
            </h1>
            {searchResults && (
              <SearchResults
                rawResults={searchResults}
                searchQueryCount={`${searchResults?.record_count},${vehiclesCount},${accessoriesCount},${partsCount},${offersCount},${otherCount}`}
                searchDataEntry={searchData.entry}
              />
            )}
          </section>
          {/* Sidebar container */}
          <div
            css={[
              tw` justify-self-end flex flex-col space-y-20 px-8 mt-20`,
              tw`lg:mt-0`,
            ]}
          >
            {/* Styling SearchSideBar Nav Desktop */}
            {vehiclesCount > 0 ||
            accessoriesCount > 0 ||
            partsCount > 0 ||
            offersCount > 0 ||
            otherCount > 0 ? (
              <div
                css={[tw`bg-gray-100 p-6 rounded-lg hidden`, tw`lg:(block)`]}
              >
                {vehiclesCount < 1 ? null : (
                  <button
                    css={[tw`flex items-center py-2`]}
                    onClick={() => {
                      scrollToSection("Vehicles")
                    }}
                  >
                    <Icon.Tire
                      color="gray-600"
                      css={[tw`h-5 pr-3`, tw`md:(pr-4 h-6)`]}
                    />
                    <h2
                      css={[
                        tw`font-sans font-book text-gray-600 text-lg text-left capitalize`,
                        tw`md:(text-xl)`,
                      ]}
                    >
                      {_("Vehicles")} ({vehiclesCount})
                    </h2>
                  </button>
                )}
                {accessoriesCount < 1 ? null : (
                  <button
                    css={[tw`flex items-center py-2`]}
                    onClick={() => {
                      scrollToSection("Accessories")
                    }}
                  >
                    <Icon.Checkmark
                      color="gray-600"
                      css={[tw`h-5 pr-3`, tw`md:(pr-4 h-5)`]}
                    />
                    <h2
                      css={[
                        tw`font-sans font-book text-gray-600 text-lg text-left capitalize`,
                        tw`md:(text-xl)`,
                      ]}
                    >
                      {_("Accessories")} ({accessoriesCount})
                    </h2>
                  </button>
                )}
                {partsCount < 1 ? null : (
                  <button
                    css={[tw`flex items-center py-2`]}
                    onClick={() => {
                      scrollToSection("Parts")
                    }}
                  >
                    <Icon.Globe
                      color="gray-600"
                      css={[tw`h-5 pr-3`, tw`md:(pr-4 h-6)`]}
                    />
                    <h2
                      css={[
                        tw`font-sans font-book text-gray-600 text-lg text-left capitalize`,
                        tw`md:(text-xl)`,
                      ]}
                    >
                      {_("Parts")} ({partsCount})
                    </h2>
                  </button>
                )}
                {offersCount < 1 ? null : (
                  <button
                    css={[tw`flex items-center py-2`]}
                    onClick={() => {
                      scrollToSection("Offers")
                    }}
                  >
                    <Icon.Search
                      color="gray-600"
                      css={[tw`h-5 pr-3`, tw`md:(pr-4 h-6)`]}
                    />
                    <h2
                      css={[
                        tw`font-sans font-book text-gray-600 text-lg text-left capitalize`,
                        tw`md:(text-xl)`,
                      ]}
                    >
                      {_("Offers")} ({offersCount})
                    </h2>
                  </button>
                )}
                {otherCount < 1 ? null : (
                  <button
                    css={[tw`flex items-center py-2`]}
                    onClick={() => {
                      scrollToSection("More")
                    }}
                  >
                    <Icon.Location
                      color="gray-600"
                      css={[tw`h-5 pr-3`, tw`md:(pr-4 h-6)`]}
                    />
                    <h2
                      css={[
                        tw`font-sans font-book text-gray-600 text-lg text-left capitalize`,
                        tw`md:(text-xl)`,
                      ]}
                    >
                      {_("More")} ({otherCount})
                    </h2>
                  </button>
                )}
              </div>
            ) : null}
            {/* End Styling SearchSideBar Nav */}

            {sidebarSections?.map((node: any) => {
              switch (node?._type) {
                case "searchQueryList":
                  return <OtherPopularSearches {...node} />

                case "imageCta":
                  return (
                    <DynamicSeriesCta
                      imageCta={imageCta}
                      seriesState={seriesState}
                    />
                  )

                case "searchSidebarCta":
                  return <SidebarCta {...node} />
              }
            })}
          </div>
        </section>
      )}

      <ImageBlocks
        imageBlocks={page?.imageBlocks}
        _key={"Search Page Image Blocks"}
        sectionSlug={{
          current: "SearchImageBlocks",
        }}
      />
    </Layout>
  )
}

const SidebarCta: React.FC = ({ heading, cta }) => (
  <section aria-label={`${heading}` + " Section"}>
    {heading && <Title>{heading}</Title>}
    <ButtonLink
      {...{ [cta?.buttonType]: true }}
      to={getCTALinkValue(cta)}
      target={cta?.linkType === "external" ? "_blank" : "_self"}
      css={[tw`mt-4`]}
    >
      {cta?.title}
    </ButtonLink>
  </section>
)

const Title: React.FC = ({ children }) => (
  <h2
    css={[tw`font-sans font-light text-base tracking-[0.2rem]`, tw`md:text-xl`]}
  >
    {children}
  </h2>
)

/**
 *
 * @author Stu
 * @summary - A list of popular search terms. Selecting a term will trigger a search.
 *
 * @returns <OtherPopularSearches />
 *
 * @todo - Pull this Popular Searches data from Sanity CMS (not yet configured). Consider combining this with ""Popular Searches" content (for the search menu bar), located in Search settings in CMS.
 * @todo - Unit tests
 */
const OtherPopularSearches: React.FC = ({ heading, queries }) => (
  <section aria-label="Other Popular Search Section">
    {heading && <Title>{heading}</Title>}
    <ul css={[tw`grid gap-y-1 font-semibold text-base mt-4`, tw`md:text-lg`]}>
      {queries?.map((node: any, index: number) => (
        <li key={node?._key}>
          <SearchQuery
            searchTerm={node?.searchTerm}
            analyticsId={index + 1}
            index={index}
          />
        </li>
      ))}
    </ul>
  </section>
)

export const query = graphql`
  query SearchQuery($id: String) {
    page: sanityPageSearch(id: { eq: $id }) {
      id
      title
      language
      imageBlocks {
        _key
        heading
        body
        imageLink {
          displayText
          internalLink {
            slug {
              _type
              current
            }
          }
        }
        image {
          ...SanityImageData
        }
      }
      sidebarSections {
        ... on SanityImageCta {
          _key
          _type
          seriesRef {
            name
          }
          heading
          image {
            ...SanityImageData
          }
          link {
            linkType
            displayText
            externalUrl
            internalLink {
              slug {
                current
              }
            }
            anchor
          }
          seriesVariants {
            seriesRef {
              name
            }
            image {
              ...SanityImageData
            }
            heading
            link {
              linkType
              displayText
              externalUrl
              internalLink {
                slug {
                  current
                }
              }
            }
          }
        }
        ... on SanitySearchQueryList {
          _key
          _type
          heading
          queries {
            _key
            searchTerm
          }
        }
        ... on SanitySearchSidebarCta {
          _key
          _type
          heading
          cta {
            linkType
            buttonType
            title
            externalUrl
            internalLink {
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`

export default Search
