import React from "react"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import { Image } from "../../atoms/Image"
import { Link } from "../../atoms/Link"
import { DynamicSeriesCtaProps } from "./DynamicSeriesCta.d"

/**
 * Dynamic Series CTA that consumes imageCta from Sanity
 * @author Tyler
 *
 * @returns {HTMLElement} <article></article>
 *
 */

const DynamicSeriesCta: React.FC<DynamicSeriesCtaProps> = ({
  imageCta,
  seriesState,
}) => {
  // Will be default if search query does not include any series names specified in series variants
  const isDefault = seriesState === "default"

  // If search query includes a series name specified in series variants, that variant is stored here
  const activeVariant = imageCta?.seriesVariants?.find(
    (node: any) => node.seriesRef.name === seriesState
  )

  // Will either be default image data or image data from a series variant
  const imageData = isDefault
    ? imageCta?.image?.image
    : activeVariant?.image?.image

  // Will either be the default alt or an alt from a series variant
  const alt = isDefault ? imageCta?.image?.alt : activeVariant?.image?.alt

  // Will either be the default heading or a heading from a series variant
  const heading = isDefault ? imageCta?.heading : activeVariant?.heading

  // Will either be the default link or a link from a series variant
  const link = isDefault ? imageCta?.link : activeVariant?.link

  if (!imageData) return
  return (
    <article
      aria-label="Series CTA"
      css={[tw`grid max-w-[22.5rem] w-full`, tw`lg:(min-w-[22.5rem])`]}
    >
      <div
        css={[tw`col-start-1 col-end-2 row-start-1 row-end-2 px-6 py-11 z-20`]}
      >
        {/* HEADING */}
        {heading && (
          <div css={[tw`text-2xl leading-[1.875rem] font-semibold `]}>
            {heading}
          </div>
        )}

        {/* LINK */}
        <Link
          to={link?.externalUrl || `/${link?.internalLink?.slug?.current}`}
          css={[tw`flex items-center gap-2`]}
        >
          {link && (
            <>
              <span css={[tw`text-xs leading-3 font-semibold`]}>
                {link?.displayText}
              </span>
              <Icon.Chevron
                direction="right"
                color="red-400"
                css={[tw`h-2.5 mt-0.5`]}
              />
            </>
          )}
        </Link>
      </div>

      {/* IMAGE */}
      <Image
        imageData={imageData}
        alt={alt}
        css={[tw`col-start-1 col-end-2 row-start-1 row-end-2 z-10`]}
      />
    </article>
  )
}

export default DynamicSeriesCta
