import { SearchResponse, SearchState } from "./Search.d"

export const search = async (state: SearchState): Promise<any> => {
  const { query } = state

  const body = {
    engine_key: process.env.GATSBY_SWIFTYPE_ENGINE_KEY_EN,
    q: query,
  }

  return fetch(
    `https://search-api.swiftype.com/api/v1/public/engines/search.json`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(results => {
      return results.json()
    })
    .then(json => json)
    .catch(error => console.error("Sorry, there was a search error:", error))
}
