import React, { useContext } from "react"
import tw from "twin.macro"
import { SearchResultProps } from "./SearchResults.d"
import SearchSection from "../SearchSection/SearchSection"
import { LanguageContext } from "../../../contexts/Language"

/**
 *
 * @author Stu
 * @summary - Display search results in pre-defined categories
 * @param {SearchRecord[]} series - Results returned from Swiftype api call
 * @param {SearchRecord[]} accessories - Results returned from Swiftype api call
 * @param {SearchRecord[]} featuredaccessory - Results returned from Swiftype api call
 * @param {SearchRecord[]} toyotacare - Results returned from Swiftype api call
 * @param {SearchRecord[]} parts - Results returned from Swiftype api call
 * @param {SearchRecord[]} toyoguard - Results returned from Swiftype api call
 * @param {SearchRecord[]} seriesaccessories - Results returned from Swiftype api call
 * @param {SearchRecord[]} xseriesmodel - Results returned from Swiftype api call
 * @param {SearchRecord[]} xseries - Results returned from Swiftype api call
 * @param {SearchRecord[]} offers - Results returned from Swiftype api call
 * @param {SearchRecord[]} offer - Results returned from Swiftype api call
 *
 * @returns <SearchResults />
 *
 * @todo - Unit tests
 * @todo - Get the original query that the user provided. Currently retrieves this from Series, but this should be handled better. Grab this from CONTEXT instead
 * @todo - Incorporate results from Inventory
 * @todo - Create a "More" setion to capture search results that are not part of default categories
 */

const SearchResults: React.FC<SearchResultProps> = ({
  rawResults,
  searchQueryCount,
  searchDataEntry,
}): JSX.Element => {
  const {
    series,
    accessories,
    featuredaccessory,
    toyotacare,
    parts,
    partsdetail,
    toyoguard,
    seriesaccessories,
    xseriesmodel,
    xseries,
    offers,
    offer,
  } = rawResults.records
  const { series: seriesInfo } = rawResults.info
  const { _ } = useContext(LanguageContext)

  const query = seriesInfo?.query
  // Make an array of the words from the query
  const queryArray = query?.split(" ")
  // Combine categories of vehicles into a general "Vehicles" results section
  const vehiclesArray = series?.concat(xseries, xseriesmodel)
  const accessoriesArray = accessories?.concat(
    seriesaccessories,
    featuredaccessory
  )
  const partsArray = parts?.concat(partsdetail)
  const offersArray = offers?.concat(offer)
  // Array for results that do not go into pre-defined categories
  const otherArray = [].concat(toyoguard, toyotacare)

  return (
    <div css={[tw`grid`]}>
      <SearchSection
        sectionTitle={_("Vehicles")}
        itemArray={vehiclesArray}
        boldWords={queryArray}
        category="vehicles"
        searchQueryCount={searchQueryCount}
        searchDataEntry={searchDataEntry}
      />
      <SearchSection
        sectionTitle={_("Accessories")}
        itemArray={accessoriesArray}
        boldWords={queryArray}
        category="accessories"
        searchQueryCount={searchQueryCount}
        searchDataEntry={searchDataEntry}
      />

      <SearchSection
        sectionTitle={_("Parts")}
        itemArray={partsArray}
        boldWords={queryArray}
        category="parts"
        searchQueryCount={searchQueryCount}
        searchDataEntry={searchDataEntry}
      />

      {/* OFFERS Section */}
      <SearchSection
        sectionTitle={_("Offers")}
        itemArray={offersArray}
        boldWords={queryArray}
        category="offers"
        searchQueryCount={searchQueryCount}
        searchDataEntry={searchDataEntry}
      />

      {/* INVENTORY Section - ToDo */}

      {/* MORE section */}
      <SearchSection
        sectionTitle={_("More")}
        itemArray={otherArray}
        boldWords={queryArray}
        category="more"
        searchQueryCount={searchQueryCount}
        searchDataEntry={searchDataEntry}
      />
    </div>
  )
}

export default SearchResults
